import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { exhaustMap, take } from 'rxjs/operators';

import { SelectLocaleState } from '@lsworks/data';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements OnDestroy {
  localeSubscription$ = new Subscription();

  errors = {
    required: '',
    email: '',
    mustMatch: '',
  };

  constructor(public translateService: TranslateService, public store: Store<any>) {}

  watchLanguageChange() {
    return this.store.select(SelectLocaleState).pipe(exhaustMap(() => of(this.loadTranslations())));
  }

  loadTranslations() {
    return this.translateService
      .get(['ui.errors.required', 'ui.errors.email', 'ui.errors.passwordMatch'])
      .subscribe((v) => {
        this.errors.required = v['ui.errors.required'];
        this.errors.email = v['ui.errors.email'];
        this.errors.mustMatch = v['ui.errors.passwordMatch'];
      });
  }

  getErrorMessage(control) {
    let message = '';

    this.localeSubscription$ = this.watchLanguageChange()
      .pipe(
        exhaustMap((item) => {
          if (control && control.errors) {
            const keys = Object.keys(control.errors);
            message = this.errors[keys[0]];
          }

          return of(message);
        }),
        take(1),
      )
      .subscribe();

    return message;
  }

  ngOnDestroy(): void {
    this.localeSubscription$.unsubscribe();
  }
}
