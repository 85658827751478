import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageComponent } from './language.component';
import { StoreModule } from '@ngrx/store';
import { MatMenuModule } from '@angular/material/menu';
import { EffectsModule } from '@ngrx/effects';
import { LocaleReducer } from './+store/locale.reducer';
import { LocaleEffects } from './+store/locale.effects';
import { TranslateModule } from '@ngx-translate/core';
import { LocaleResource } from './resources/locale.resource';

@NgModule({
  declarations: [LanguageComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature('locale', LocaleReducer),
    EffectsModule.forFeature([LocaleEffects]),
    TranslateModule.forChild(),
    MatMenuModule,
  ],
  exports: [LanguageComponent],
  providers: [LocaleResource],
})
export class LanguageModule {}
