import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './components/layout/layout.module';
import { LanguageModule } from './components/language/language.module';
import { FormControlsModule } from './components/form-controls/form-controls.module';
import { ButtonModule } from './components/button/button.module';
import { CookiesConsentModule } from './dialogs/cookies/cookies-dialog.module';

@NgModule({
  imports: [CommonModule, LayoutModule, FormControlsModule, LanguageModule, ButtonModule, CookiesConsentModule],
  exports: [LayoutModule, ButtonModule],
})
export class UiModule {}
