import { createAction, props } from '@ngrx/store';

export enum MenuActionTypes {
  MenuItems = '[Menu] Load Items',
  SideMenuToggle = '[Side Menu] Toggle',
}

export const SideMenuToggle = createAction(MenuActionTypes.SideMenuToggle);

export const MenuItems = createAction(MenuActionTypes.MenuItems, props<any>());
