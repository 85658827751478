import { environment } from '../../../environments/environment';

export interface LocaleState {
  locale: LocaleInterface;
}

export interface LocaleInterface {
  language: string;
}

export const initialState: LocaleInterface = {
  language: getNavLocale(navigator.language),
};

export function getNavLocale(lang) {
  const language = lang || navigator.language;
  return environment.languages.find((item) => item.toLowerCase().indexOf(language.toLowerCase()) !== -1) || 'en-US';
}
