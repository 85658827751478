import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';

import { ErrorService } from './services/error.service';
import { InputComponent } from './input/input.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SlideSearchComponent } from './slide-search/slide-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from './select/select.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    InputComponent,
    TextAreaComponent,
    InputSearchComponent,
    SlideSearchComponent,
    SelectComponent,
    SelectSearchComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forChild(),
    MatSelectModule,
    MatAutocompleteModule,
  ],
  exports: [
    InputComponent,
    TextAreaComponent,
    InputSearchComponent,
    SlideSearchComponent,
    SelectSearchComponent,
    SelectComponent,
  ],
  providers: [ErrorService],
})
export class FormControlsModule {}
