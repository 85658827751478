import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToasterManagerService {
  timer = 5000;

  constructor(public snackBar: MatSnackBar, public translateService: TranslateService) {}

  doToast(msg, act) {
    this.snackBar.open(msg, act, {
      duration: this.timer,
    });
  }

  doToastTranslate(msg, act, obj = {}) {
    this.translateService.get([msg, act], obj).subscribe((item) => {
      this.snackBar.open(item[msg], item[act], {
        duration: this.timer,
      });
    });
  }
}
