import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NavigateAction } from '@lsworks/data';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router) {}

  router$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigateAction),
        tap(({ route }) => {
          const myRoute = typeof route === 'string' ? [route] : route;
          this.router.navigate(myRoute);
        }),
      ),
    { dispatch: false },
  );
}
