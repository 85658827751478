import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ToasterManagerService } from './toaster-manager.service';
import { MessageEffects } from './+store/message.effect';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatSnackBarModule, EffectsModule.forFeature([MessageEffects])],
  providers: [ToasterManagerService],
})
export class ToasterManagerModule {}
