<!-- Special use case for a button that has an image passed in as an icon -->
<ng-container *ngIf="type === 'img-icon'; else regular">
  <div class="img-icon">
    <img [src]="img" width="100%" />
  </div>
</ng-container>

<!-- Any other type of button -->
<ng-template #regular>
  <button [type]="type" [class]="size" #button>
    <ng-container *ngIf="icon">
      <i [class]="icon"></i>
    </ng-container>
    {{ label | translate }}
  </button>
</ng-template>
