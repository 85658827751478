import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuUtil } from '../../../services/menu/menu-manager';
import { Observable } from 'rxjs';
import { BaseNavigator, NavigateAction } from '@lsworks/data';
import { Store } from '@ngrx/store';
import { websiteMenuItems } from '../constants/website-menu.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav', { static: false }) _sidenav: any;
  menuList$: Observable<any>;

  public collapsedMenu = '';

  public websiteMenuItems = websiteMenuItems;

  constructor(public menuUtil: MenuUtil, private store: Store<any>, private router: Router) {}

  ngOnInit(): void {
    this.menuList$ = this.menuUtil.loadMenuState();
  }

  clickPage(item) {
    this.menuUtil.clickPageToggle(this._sidenav, item);
  }

  toggleMenu() {
    this.menuUtil.toggleMenu(this._sidenav);
  }

  ngAfterViewInit(): void {
    this.menuUtil.loadEffectMenu(this._sidenav);
  }

  goToHome() {
    const route = BaseNavigator.getPageHomeRoute();
    this.store.dispatch(NavigateAction({ route }));
    this.toggleMenu();
  }

  collapseMenu(item) {
    const label = item.title ? item.title : item;

    if (this.collapsedMenu !== label) {
      this.collapsedMenu = label;
    } else {
      this.collapsedMenu = '';
    }
  }
}
