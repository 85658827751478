export const IP_API_URL = 'http://ip-api.com/json';

export const FUNCTIONS_URL = 'https://' + window.location.host + '/.netlify/functions/getCountry';

export const COUNTRIES_API_URL = 'https://countriesnow.space/api/v0.1/countries/iso';

export const FUNCTIONS_DIRECTORY = '.netlify/functions/countryHandler';

// @TODO remove this when we have the reserved regions running on the API

export const reservedRegions = [
  {
    orgName: 'canadvance',
    countries: ['us', 'usa', 'united states', 'united states of america'],
    countryOBJ: {
      name: 'United States',
      Iso2: 'US',
      Iso3: 'USA',
    },
    provinces: [],
  },
  {
    orgName: 'southafrica',
    countries: ['za', 'sa', 'south africa'],
    countryOBJ: {
      name: 'South Africa',
      Iso2: 'ZA',
      Iso3: 'SA',
    },
    provinces: [],
  },
  {
    orgName: 'ihs',
    countries: ['kw', 'kuwait', 'kwt'],
    provinces: [],
  },
];

export const USCODE = 'US';

export const KUWAITCODE = 'KW';

export const CANADACODE = 'CA';
