import { MenuInterface } from '@lsworks/data';

export class MenuData {
  static GetMenu(): MenuInterface[] {
    return [
      {
        title: 'label.readLs',
        icon: '',
        action: '/read-ls',
      },
      // Christimas Carol Disabled
      // {
      //   'title': 'A Christmas Carol',
      //   'icon': '',
      //   'action': '/christmas-carol',
      // },
      // {
      {
        title: 'label.testimonials',
        icon: '',
        action: '/testimonials',
      },
      {
        title: 'label.pricing',
        icon: '',
        action: '/testimonials',
      },
      {
        title: 'label.findAProvider',
        icon: '',
        action: '/testimonials',
      },
    ];
  }
}
