<!-- Desktop header -->
<header #header *ngIf="width === 'desktop'" [class]="width">
  <div class="logo" (click)="goHome()">
    <img src="assets/img/neuralign/neuralign-logo.png" width="100%" />
  </div>

  <nav>
    <ng-container *ngFor="let item of menuItems.links">
      <!-- Render items with drop down menus -->
      <ng-template [ngIf]="item.subMenu">
        <button mat-button class="menuItem" [matMenuTriggerFor]="subMenu">
          {{ item.label | translate }}
        </button>
        <mat-menu #subMenu="matMenu" class="mat-menu">
          <button
            mat-menu-item
            *ngFor="let item of item.subMenu"
            (click)="goto(item.redirect)"
            [disabled]="!item.redirect"
          >
            {{ item.label | translate }}
          </button>
        </mat-menu>
      </ng-template>

      <!-- Render items without drop down menus -->
      <ng-template [ngIf]="!item.subMenu">
        <button
          mat-button
          class="menuItem"
          (click)="goto(item.redirect)"
          [disabled]="!item.redirect"
        >
          {{ item.label | translate }}
        </button>
      </ng-template>
    </ng-container>

    <div class="buttons w-10 px-0 text-right" *ngIf="menuItems.buttons">
      <ng-container *ngFor="let item of menuItems.buttons">
        <!-- Render items with drop down menus -->
        <ng-container *ngIf="item.subMenu">
          <lsw-button
            mat-button
            class="menuItem"
            [matMenuTriggerFor]="subMenu"
            [label]="'common.try-now'"
            [type]="'primary'"
            [overrideDisable]="true"
          >
          </lsw-button>
          <mat-menu #subMenu="matMenu" class="mat-menu">
            <button
              mat-menu-item
              *ngFor="let item of item.subMenu"
              (click)="goto(item.redirect)"
              [disabled]="!item.redirect"
            >
              {{ item.label | translate }}
            </button>
            <button mat-menu-item (click)="becomeAProvider()">
              {{ 'shop.become-a-provider' | translate }}
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </div>
  </nav>
</header>

<!-- Mobile header -->
<header #header *ngIf="width === 'mobile'" [class]="width">
  <div class="logo" (click)="goHome()">
    <img src="assets/img/neuralign/neuralign-logo.png" width="100%" />
  </div>

  <button mat-icon-button (click)="toggleSideMenu()" onclick="this.blur()">
    <mat-icon>menu</mat-icon>
  </button>
</header>
