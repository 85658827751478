import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@lsworks/data';
import { ToolsModule } from '@lsworks/tools';
import { UiModule } from '@lsworks/ui';
import { HttpLoaderFactory, metaReducers } from './shared/utils/module-utils';
import { RouterEffects } from './shared/+store/router/router.effects';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DimensionComponent } from './shared/utils/dimension/dimension.component';
import { CurtainModule } from './shared/components/curtain/curtain.module';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [AppComponent, DimensionComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({
      // disable animations for browsers that don't support web animations
      disableAnimations:
        !('animate' in document.documentElement) ||
        (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent)),
    }),
    HttpClientModule,
    FlexLayoutModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([RouterEffects]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          logOnly: environment.production,
        })
      : [],
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    ToolsModule,
    UiModule,
    CurtainModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
