export enum Colour {
  PURPLE = '#393473',
  RICH_PURPLE = '#480079',
  LIGHT_PURPLE = '#BBB8E1',
  ORANGE = '#FDBD4F',
  WHITE = '#FFF',
  BLACK = '#000',
  TRANSPARENT = 'transparent',
  DISABLED = '#CCC',
  TRANSLUCENT = '#FFFFFFAA',
}
