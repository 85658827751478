import { createAction, props } from '@ngrx/store';
import { LocaleInterface } from '@lsworks/data';

// TODO: criar actions de recover password
export enum LocaleActionTypes {
  LocaleManual = '[APP] Locale Manual',
  LocaleChange = '[APP] Locale Change',
  LocaleSuccess = '[APP] Locale Success',
  LocaleFail = '[APP] Locale Fail',
}

export const LocaleLogon = createAction(LocaleActionTypes.LocaleManual, props<LocaleInterface>());

export const LocaleChange = createAction(LocaleActionTypes.LocaleChange, props<LocaleInterface>());

export const LocaleSuccess = createAction(LocaleActionTypes.LocaleSuccess, props<LocaleInterface>());

export const LocaleFail = createAction(LocaleActionTypes.LocaleFail, props<{ error }>());
