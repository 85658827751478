import { Action, createReducer, on } from '@ngrx/store';
import { MenuItemsInterface, menuItemsState } from '@lsworks/data';
import { MenuItems } from './menu.actions';

const _MenuItemsReducer = createReducer(
  menuItemsState,
  on(MenuItems, (state, data) => {
    return { ...state, items: data };
  }),
);

export function MenuItemsReducer(state: MenuItemsInterface | undefined, action: Action) {
  return _MenuItemsReducer(state, action);
}
