<footer>
  <div class="site-map">
    <h3 class="title">{{ 'common.explore-neuralign' | translate }}</h3>
    <div class="links">
      <lsw-button
        *ngFor="let link of siteMap; let i = index"
        [ngClass]="{ rightBorder: drawBorderLine(i) }"
        [label]="link.label"
        [type]="'footer-link'"
        [redirect]="link.redirect"
        (click)="goto(link.redirect, link.fragment, link.isOutsideLink)"
      >
      </lsw-button>
    </div>
  </div>

  <div class="social-media-container">
    <div class="social-media">
      <div
        (click)="goto('shop')"
        class="canAdvanceLogo text-center"
        *ngIf="showCanAdvanceLogo()"
      >
        <div class="d-flex justify-content-center">
          <img src="assets/img/CanAdvance_logo_white.webp" />
        </div>
        <span>
          {{ 'common.canAdvanceDistributor' | translate }}
        </span>
      </div>
      <div class="socials justify-content-around mt-4">
        <lsw-button
          [outsideLink]="'https://www.facebook.com/Neuralignedu'"
          [icon]="'fa fa-facebook'"
          [type]="'icon'"
        ></lsw-button>
        <lsw-button
          [outsideLink]="'https://twitter.com/Neuralign'"
          [img]="'assets/imgs/icons/x-logo-white.webp'"
          [type]="'img-icon'"
        ></lsw-button>
        <lsw-button
          [outsideLink]="'https://www.instagram.com/neuralign_edu/'"
          [icon]="'fa fa-instagram'"
          [type]="'icon'"
        ></lsw-button>
        <lsw-button
          [outsideLink]="'https://www.linkedin.com/company/neuralign/'"
          [icon]="'fa fa-linkedin'"
          [type]="'icon'"
        ></lsw-button>
        <lsw-button
          [outsideLink]="'https://www.tiktok.com/@neuralign'"
          [img]="'assets/imgs/icons/tiktok.webp'"
          [type]="'img-icon'"
        ></lsw-button>
      </div>

      <h4 class="stay-connected">{{ 'common.stay-connected' | translate }}</h4>
      <form
        #myForm
        method="post"
        novalidate
        action="https://works.us20.list-manage.com/subscribe/post?u=3fde7f875f862b1ffd7f2ea5b&id=79aa18c6fa"
        target="_blank"
      >
        <input
          class="email-input"
          name="EMAIL"
          value=""
          [placeholder]="'form.email' | translate"
          autocomplete="none"
        />
        <button (click)="sendForm(myForm)">
          {{ 'label.ok' | translate }}
        </button>
      </form>
      <div class="privacy-policy">
        <a [routerLink]="['privacy-policy']">{{
          'privacy-policy.title' | translate
        }}</a>
      </div>
    </div>
  </div>
</footer>
