import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CountryHandlerService } from '../../services/country-handler-service/country-handler.service';

/**
 * HideFromUsaGuard hides a route from US providers.
 */
@Injectable({
  providedIn: 'root',
})
export class HideFromUsaGuard implements CanActivate {
  constructor(
    private _countryHandler: CountryHandlerService,
    private _router: Router,
    private _snackbar: MatSnackBar,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._countryHandler.userCountry === 'US') {
      this._router.navigateByUrl('/home');

      this._snackbar.open(
        `Feature '${state.url}' is not available yet. Please try again later or contact us.`,
        'close',
        {
          verticalPosition: 'top',
          duration: 5000,
        },
      );

      return false;
    } else {
      return true;
    }
  }
}
