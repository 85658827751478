import { MenuInterface } from '../../interface/menu.interface';

export interface SideMenuState {
  sideMenu: SideMenuInterface;
}

export interface MenuItemsState {
  menuItems: MenuItemsInterface;
}

export interface SideMenuInterface {
  opened: boolean;
}

export interface MenuItemsInterface {
  items: MenuInterface[];
}

export const sideMenuState: SideMenuInterface = {
  opened: false,
};

export const menuItemsState: MenuItemsInterface = {
  items: [],
};
