export const environment = {
  production: true,
  languages: ['en-US', 'es-AR'],
  API_URL: 'https://api.ls.works/',
  API_EMAIL_URL: 'https://api.ls.works/contact',
  API_PROVIDER_URL: 'https://api.ls.works/organization/publicProfiles/getPublicProfiles',
  ADMIN_ORG_SUBDOMAIN: 'steppingstones',
  DEV_API: 'https://api.lsworks.dev/',
  ADMIN_EMAIL: 'support@lsworks.org',
  SENTRY_DSN: 'https://0e4fd13f51462ff98ca3620f2b56638a@o237770.ingest.us.sentry.io/4507227233714176',
  CLARITY_ID: 'mg1ytmba3l',
  GOOGLE: {
    MAPS_API: 'AIzaSyDNvWQZeoScFsgDbqSBtxSCvymnSAgldX8',
    ADWORDS_CONVERSION_ID: 'AW-753718932',
    ADWORDS_CONVERSION_LABEL: '6wvHCNPvnqoZEJSts-cC',
    ANALYTICS_TAG: 'G-M1QEB5C4W4',
    ADWORDS_TAG: 'AW-753718932',
  },
};
