import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Colour } from '../../styles/colors';

@Component({
  selector: 'lsw-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements AfterViewInit {
  @Input() type: 'default' | 'menu' | 'outline' | 'icon' | 'img-icon' | 'primary' | 'secondary' | 'footer-link' =
    'default';
  @Input() redirect: string | undefined; // redirect to pages within the app
  @Input() outsideLink: string | undefined; // go to outside pages in new tab
  @Input() reportLink: string; // go to a report page
  @Input() mailTo: boolean | undefined;
  @Input() label: string;
  @Input() icon: string;
  @Input() size: null | 'small' | 'responsive' = null;
  @Input() overrideDisable: boolean = false;
  @Input() img: string = '';
  @Input() trackClick: boolean = false;

  @ViewChild('button') button: ElementRef<HTMLButtonElement>;

  @HostListener('click')
  handleRedirect() {
    if (this.hasNoLink()) return;

    if (this.redirect) {
      this._router.navigate([`${this.redirect}`]);
    } else if (this.outsideLink) {
      window.open(this.outsideLink, '_blank');
    } else if (this.reportLink) {
      this._router.navigate(['reports', this.reportLink]);
    }

    if (this.trackClick) {
      // Send tag to clarity
      (window as any)?.clarity?.('set', 'clicked', this.label);
    }
  }

  constructor(private _router: Router) {}

  ngAfterViewInit() {
    this.setPrimaryButtonAnimation();
    this.setDisabledStyle();
  }

  setPrimaryButtonAnimation() {
    if (this.type === 'primary') {
      this.setOnMouseEnter(() => {
        this.button.nativeElement.classList.add('in');
        this.button.nativeElement.classList.remove('out');
      });
      this.setOnMouseLeave(() => {
        this.button.nativeElement.classList.remove('in');
        this.button.nativeElement.classList.add('out');
      });
    }
  }

  setDisabledStyle() {
    if (this.overrideDisable) {
      return;
    }

    if (this.isButtonDisabled()) {
      this.setColor(Colour.DISABLED);
      this.button.nativeElement.style.cursor = 'default';

      this.setOnMouseEnter(() => {
        this.setColor(Colour.DISABLED);
      });
      this.setOnMouseLeave(() => {
        this.setColor(Colour.DISABLED);
      });
    }
  }

  // Helpers

  setColor(color: Colour) {
    this.button.nativeElement.style.color = color;
  }

  setBackgroundColor(color: Colour) {
    this.button.nativeElement.style.backgroundColor = color;
  }

  setOnMouseEnter(fn: any) {
    this.button.nativeElement.onmouseenter = fn;
  }

  setOnMouseLeave(fn: any) {
    this.button.nativeElement.onmouseleave = fn;
  }

  setWidth(width: number) {
    this.button.nativeElement.style.width = `${width}rem`;
  }

  isButtonDisabled() {
    if (this.hasNoLink()) {
      return true;
    } else {
      return false;
    }
  }

  private hasNoLink() {
    return !this.redirect && !this.outsideLink && !this.mailTo && !this.reportLink;
  }
}
