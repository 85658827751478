<lsw-curtain></lsw-curtain>

<div class="site-container">
  <ui-header></ui-header>
  <ui-side-menu></ui-side-menu>

  <div class="site-content">
    <mat-sidenav-container fxFlexFill>
      <mat-sidenav-content fxFlexFill>
        <ng-content></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <ui-footer></ui-footer>
</div>
