import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CountryHandlerService } from '../../services/country-handler-service/country-handler.service';

@Injectable({
  providedIn: 'root',
})
export class GetUserCountryGuard implements CanActivate {
  constructor(private _countryHandler: CountryHandlerService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this._countryHandler.initCountry();

    return true;
  }
}
