import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { LocaleChange, LocaleFail, LocaleSuccess } from './locale.actions';
import { MessageAction } from '@lsworks/tools';
import { LocaleResource } from '../resources/locale.resource';

@Injectable()
export class LocaleEffects {
  localeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocaleChange),
      map((action) => action),
      exhaustMap(({ language }) =>
        this.localeResource.setLocale(language).pipe(
          map(() => LocaleSuccess({ language })),
          catchError((res) => of(LocaleFail(res))),
        ),
      ),
    ),
  );

  localeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocaleSuccess),
        tap(() => {
          const message = 'ui.effects.success.localeSuccess';
          this.store.dispatch(MessageAction({ message }));
        }),
      ),
    { dispatch: false },
  );

  localeFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocaleFail),
        tap(({ error }) => {
          const message = error?.message || 'ui.effects.errors.localeError';
          this.store.dispatch(MessageAction({ message }));
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private store: Store<any>, private localeResource: LocaleResource) {}
}
