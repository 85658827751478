<mat-sidenav #sidenav position="end">
  <div class="sidenav-header">
    <div class="logo" (click)="goToHome()">
      <img
        src="assets/img/neuralign/neuralign-logo.png"
        [alt]="'ui.logos.alt.lsworks' | translate"
        width="100%"
      />
    </div>
    <a (click)="toggleMenu()" class="close-button">
      <mat-icon>cancel</mat-icon>
    </a>
  </div>

  <mat-nav-list class="menu">
    <button (click)="clickPage('/shop')" class="ls-btn ls-btn-outline buy-now">
      {{ 'menus.buy-now' | translate }}
    </button>

    <ng-container *ngFor="let item of websiteMenuItems">
      <ng-template [ngIf]="item.subMenu">
        <button
          mat-list-item
          *ngFor="let item of item.subMenu"
          (click)="clickPage(item.redirect)"
          [disabled]="!item.redirect"
        >
          {{ item.label | translate }}
        </button>
      </ng-template>
      <ng-template [ngIf]="!item.subMenu">
        <button
          mat-list-item
          (click)="clickPage(item.redirect)"
          [disabled]="!item.redirect"
        >
          {{ item.label | translate }}
        </button>
      </ng-template>
    </ng-container>
  </mat-nav-list>
</mat-sidenav>
