import { Action, createReducer, on } from '@ngrx/store';

import { initialState, LocaleInterface } from '@lsworks/data';
import { LocaleFail, LocaleLogon, LocaleSuccess } from './locale.actions';

const _LocaleReducer = createReducer(
  initialState,
  on(LocaleLogon, (state, loc) => ({ ...state, language: loc.language })),
  on(LocaleSuccess, (state, loc) => ({ ...state, language: loc.language })),
  on(LocaleFail, (state, { error }) => ({ ...state, error })),
);

export function LocaleReducer(state: LocaleInterface | undefined, action: Action) {
  return _LocaleReducer(state, action);
}
