import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getNavLocale, SelectLocaleState } from '@lsworks/data';

@Injectable({
  providedIn: 'root',
})
export class InternationalizationManagerService implements OnDestroy {
  localeSubscription$ = new Subscription();

  constructor(public translate: TranslateService, public store: Store<any>) {
    this.checkLanguageInAssets();

    this.localeSubscription$ = this.store
      .select(SelectLocaleState)
      .pipe(tap(({ language }) => this.setLanguageNavigator(language)))
      .subscribe();
  }

  private checkLanguageInAssets() {
    const language = getNavLocale(navigator.language);
    this.translate.use(language).subscribe(
      async () => {
        await this.setLanguageNavigator(language);
      },
      async () => {
        await this.setLanguageNavigator('en-US');
      },
    );
  }

  public async setLanguageNavigator(lang: string) {
    lang = getNavLocale(lang);
    this.translate.setDefaultLang(lang);
    moment.locale(lang);

    return await this.translate.use(lang).toPromise();
  }

  public getLanguage() {
    return this.translate.getDefaultLang();
  }

  public getCountry(language: string): string {
    if (language.split('-') && language.split('-')[1]) {
      return language.split('-')[1];
    }
    return '';
  }

  ngOnDestroy(): void {
    this.localeSubscription$.unsubscribe();
  }
}
