import { MenuItem } from './menuItem.interface';

export const siteMap: MenuItem[] = [
  {
    label: 'common.about-us',
    redirect: 'about-us',
  },
  {
    label: 'common.non-profit',
    redirect: 'foundation',
  },
  {
    label: 'common.what-is-dyslexia',
    redirect: 'what-is-dyslexia',
  },
  {
    label: 'common.news',
    redirect: 'news',
  },
  {
    label: 'common.how-does-it-work',
    redirect: 'how-it-works',
  },
  {
    label: 'common.for-kids',
    redirect: 'for-kids',
  },
  {
    label: 'common.what-is-adhd',
    redirect: 'adhd',
  },
  {
    label: 'common.contact-us',
    redirect: 'contact-us',
  },

  {
    label: 'common.demo',
    redirect: 'demo',
  },
  {
    label: 'common.for-adults',
    redirect: 'for-adults',
  },
  {
    label: 'common.what-is-autism',
    redirect: 'what-is-autism',
  },
  {
    label: 'common.faq',
    redirect: 'faq',
  },
  {
    label: 'common.testimonials',
    redirect: 'testimonials',
  },
  {
    label: 'common.for-educators',
    redirect: 'practitioners',
  },
  {
    label: 'common.cerebral-palsy',
    redirect: 'cerebral-palsy',
  },
  {
    label: 'common.community',
    redirect: 'https://www.facebook.com/groups/837870984235611',
    isOutsideLink: true,
  },
  {
    label: 'common.evidence-basis',
    redirect: 'evidence',
  },
  {
    label: 'common.dyslexia-screening',
    redirect: 'dyslexia-screening',
  },
  {
    label: 'common.blog',
    redirect: 'blog',
  },
];
