import { Injectable } from '@angular/core';

export interface CDHServiceFeatures {
  isActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CDHService implements CDHServiceFeatures {
  private _currentMonth: string = new Date().toDateString().split(' ')[1];

  get isActive() {
    if (this._currentMonth == 'Nov' || this._currentMonth == 'Dec' || this._currentMonth == 'Jan') {
      return true;
    }
    return false;
  }
}
