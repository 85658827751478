// TODO add on Ingrid's dashboard a way to save which organization will receive the b2cs from non reserved regions

import { PaymentTypes } from './shared/services/country-handler-service/interfaces/country-handler.interfaces';

export const LSWORKS_REGISTER_URL = 'https://ls.works/authCallback?b2csignup=true&subdomain=neuralignacademy';
export const CANADVANCE_REGISTER_URL = 'https://ls.works/authCallback?b2csignup=true&subdomain=neuralignusa';

export const CANADVANCE = 'canadvance';
export const NEURALIGNUSA = 'neuralignusa';
export const NEURALIGN_ACADEMY = 'neuralignacademy';
export const CANADVANCE_URL = 'https://canadvance.ls.works/';
export const NEURALIGN_ACADEMY_URL = 'https://neuralignacademy.ls.works/';
export const NEURALIGN_USA_URL = 'https://neuralignusa.ls.works/';
export const LS_DOT_WORKS = 'https://ls.works/';

export const DYSLEXIA_SCREENING_SURVEY_URL =
  'https://readls.surveysparrow.com/s/Neuralign-Dyslexia-Screening/tt-cfc32d?';

export const calculateSavingPercentage = (discountedPrice: number, fullPrice: number = 1500): number => {
  const savingAmount = fullPrice - discountedPrice;

  return Math.floor((savingAmount / fullPrice) * 100);
};

export const getTokenPrice = (tokenPrice: number, paymentType: PaymentTypes) => {
  let price = tokenPrice;

  switch (paymentType) {
    case PaymentTypes.MONTHLY_INSTALLMENT_PAYMENT:
      price = tokenPrice * 12;
      break;
    case PaymentTypes.SPLIT_PAYMENTS:
      price = tokenPrice + tokenPrice * 0.2;
    default:
      break;
  }

  return price;
};
