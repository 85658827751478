import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { MessageAction } from './message.action';
import { ToasterManagerService } from '../toaster-manager.service';

@Injectable()
export class MessageEffects {
  constructor(private actions$: Actions, private toasterService: ToasterManagerService) {}

  message$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessageAction),
        tap((act) => {
          this.toasterService.doToastTranslate(act.message, 'label.close', act.obj);
        }),
      ),
    { dispatch: false },
  );
}
