export class ArrayUtil {
  static objValues(obj) {
    return Object.keys(obj).map((key) => {
      return obj[key];
    });
  }

  static chunk(arr, chunkSize) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize));
    return R;
  }
}
