import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GetUserCountryGuard, HideFromUsaGuard } from './shared/guards';
import { IsChristmasTimeGuard } from './shared/guards/is-christmas-time-guard/is-christmas-time.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'neuralign-academy',
    loadChildren: () => import('./neuralign-academy/neuralign-academy.module').then((m) => m.NeuralignAcademyModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'how-it-works',
    loadChildren: () => import('./how-it-works/how-it-works.module').then((m) => m.HowItWorksModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'evidence',
    loadChildren: () => import('./evidence/evidence.module').then((m) => m.EvidenceModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'foundation',
    loadChildren: () => import('./foundation/foundation.module').then((m) => m.FoundationModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'read-ls-home',
    loadChildren: () => import('./read-ls-home/read-ls-home.module').then((m) => m.ReadLsHomeModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'christmas-carol',
    loadChildren: () => import('./christmas-carol/christmas-carol.module').then((m) => m.ChristmasCarolModule),
    canActivate: [GetUserCountryGuard, IsChristmasTimeGuard],
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then((m) => m.DemoModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'testimonials',
    loadChildren: () => import('./testimonials/testimonials.module').then((m) => m.TestimonialsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then((m) => m.AboutUsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'biography',
    loadChildren: () => import('./biographies/biographies.module').then((m) => m.BiographiesModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'find-provider',
    loadChildren: () => import('./find-provider/find-provider.module').then((m) => m.FindProviderModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'signup',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'signup/home',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pricing/pricing.module').then((m) => m.PricingModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'rightToRead',
    loadChildren: () => import('./right-to-readls/right-to-readls.module').then((m) => m.RightToReadlsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'for-adults',
    loadChildren: () => import('./readls-for-adults/readls-for-adults.module').then((m) => m.ReadlsForAdultsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'research',
    loadChildren: () => import('./research/research.module').then((m) => m.ResearchModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'practitioners',
    loadChildren: () => import('./practitioners/practitioners.module').then((m) => m.PractitionersModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'what-is-autism',
    loadChildren: () => import('./what-is-autism/what-is-autism.module').then((m) => m.WhatIsAutismModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'what-is-dyslexia',
    loadChildren: () => import('./what-is-dyslexia/what-is-dyslexia.module').then((m) => m.WhatIsDyslexiaModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'fundraising',
    loadChildren: () => import('./fundraising/fundraising.module').then((m) => m.FundraisingModule),
    canActivate: [GetUserCountryGuard, HideFromUsaGuard],
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then((m) => m.NewsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'adhd',
    loadChildren: () => import('./adhd/adhd.module').then((m) => m.AdhdModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'for-kids',
    loadChildren: () => import('./for-kids/for-kids.module').then((m) => m.ForKidsModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'cerebral-palsy',
    loadChildren: () => import('./cerebral-palsy/cerebral-palsy.module').then((m) => m.CerebralPalsyModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'dyslexia-screening',
    loadChildren: () => import('./dyslexia-screening/dyslexia-screening.module').then((m) => m.DyslexiaScreeningModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'brand-guide',
    loadChildren: () => import('./brand-guide/brand-guide.module').then((m) => m.BrandGuideModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
    canActivate: [GetUserCountryGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./report-pdf/report-pdf.module').then((m) => m.ReportPdfModule),
    canActivate: [GetUserCountryGuard],
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
