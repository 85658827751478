import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookiesService } from '../../../services/menu/cookies.service';
import { ManageCookiesDialog } from '../manage-cookies/manage-cookies.dialog';

@Component({
  selector: 'lsw-button',
  templateUrl: './cookies-consent.dialog.html',
  styleUrls: ['./cookies-consent.dialog.scss'],
})
export class CookiesConsentDialog {
  constructor(private _dialog: MatDialog, private _cookies: CookiesService) {}

  public handleAcceptAll() {
    this._cookies.save({
      hasChosenCookiePreference: true,
      load: { analytics: true, clarity: true, sentry: true },
    });
  }

  public handleManageCookies() {
    this._dialog.open(ManageCookiesDialog, { disableClose: true, width: '500px' });
  }
}
