import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'lsw-curtain',
  templateUrl: './curtain.component.html',
  styleUrls: ['./curtain.component.scss'],
  animations: [
    trigger('fade', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms cubic-bezier(0.900, -0.410, 0.750, 0.750)', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CurtainComponent implements AfterViewInit {
  isLoading: boolean = true;

  ngOnInit() {
    document.body.style.overflow = 'hidden';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoading = false;
      document.body.style.overflow = 'initial';
    }, 1000);
  }
}
