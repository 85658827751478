import { SideMenuToggle } from './menu.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { SideMenuInterface, sideMenuState } from '@lsworks/data';

const _SideMenuReducer = createReducer(
  sideMenuState,
  on(SideMenuToggle, (state) => {
    return { ...state, opened: !state.opened };
  }),
);

export function SideMenuReducer(state: SideMenuInterface | undefined, action: Action) {
  return _SideMenuReducer(state, action);
}
