import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CookiesConsentDialog } from './cookies-consent/cookies-consent.dialog';
import { ManageCookiesDialog } from './manage-cookies/manage-cookies.dialog';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CookiesConsentDialog, ManageCookiesDialog],
  imports: [CommonModule, TranslateModule.forChild(), MatDialogModule, ReactiveFormsModule, MatSlideToggleModule],
  exports: [CookiesConsentDialog],
})
export class CookiesConsentModule {}
