import { environment } from '@lsworks/data';
import { BrowserProfilingIntegration, browserTracingIntegration, init, replayIntegration } from '@sentry/angular-ivy';

export function initializeSentry() {
  const location = window.location.toString();
  const isLocalHost = location.includes('localhost') || location.includes('127.0.0.1');
  if (isLocalHost) {
    return;
  }

  // Set environment tag for Sentry
  const getEnvironment = (): 'development' | 'production' | 'localhost' => {
    // even with the early return, keep it here in case it is needed for testing
    if (isLocalHost) {
      return 'localhost';
    } else if (location.includes('netlify')) {
      return 'development';
    } else {
      return 'production';
    }
  };

  init({
    dsn: environment.SENTRY_DSN,
    environment: getEnvironment(),
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
      // Add browser profiling integration to the list of integrations
      new BrowserProfilingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
  });
}
