import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { SideMenuReducer } from './+store/side-menu.reducer';
import { MenuItemsReducer } from './+store/menu-items.reducer';
import { MenuUtil } from '../../services/menu/menu-manager';

import { LanguageModule } from '../../components/language/language.module';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonModule } from '../button/button.module';
import { RouterModule } from '@angular/router';
import { CurtainModule } from '../../../../../../apps/lsworks/src/app/shared/components/curtain/curtain.module';

@NgModule({
  declarations: [LayoutComponent, SideMenuComponent, TopMenuComponent, HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    StoreModule.forFeature('sideMenu', SideMenuReducer),
    StoreModule.forFeature('menuItems', MenuItemsReducer),
    LanguageModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
    ButtonModule,
    RouterModule,
    CurtainModule,
  ],
  exports: [LayoutComponent, SideMenuComponent, TopMenuComponent, HeaderComponent, FooterComponent],
  providers: [MenuUtil],
})
export class LayoutModule {}
