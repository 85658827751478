import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { includes } from 'lodash';
import { environment } from '@lsworks/data';

const DEV_URL = 'https://api.lsworks.dev/';
const BASE_URL = environment.API_URL;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _http: HttpClient) {}

  get<T>(endpoint, params?): Observable<T> {
    if (includes(window.location.href, 'deploy-preview')) {
      return this._http.get<T>(`${DEV_URL}${endpoint}`, { params });
    } else {
      return this._http.get<T>(`${BASE_URL}${endpoint}`, { params });
    }
  }

  post<T>(endpoint, body): Observable<T> {
    if (includes(window.location.href, 'deploy-preview')) {
      return this._http.post<T>(`${DEV_URL}${endpoint}`, body);
    } else {
      return this._http.post<T>(`${BASE_URL}${endpoint}`, body);
    }
  }
}
