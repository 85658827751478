import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@lsworks/data';
import { AppModule } from './app/app.module';
import { initializeSentry } from './sentry';
import { InitializeClarity } from './clarity';
import { loadDevShortcuts } from './scripts/devHelpers';

if (environment.production) {
  // Enable prod mode must be called before anything (Specially any angular related code)
  enableProdMode();
  // Initialize Sentry
  initializeSentry();

  // initialize Clarity
  InitializeClarity();
}

if (isDevMode()) {
  loadDevShortcuts();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
