import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class LocaleResource {
  constructor(private http: HttpClient) {}

  setLocale(language: string): Observable<any> {
    return of(language);
  }
}
