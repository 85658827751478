<h2>Manage Cookies</h2>

<form [formGroup]="form">
  <div class="grid">
    <p class="bold">Necessary</p>
    <p>Some cookies we use are necessary for the operation of this website.</p>
    <div>
      <mat-slide-toggle [checked]="true" [disabled]="true"></mat-slide-toggle>
    </div>
  </div>

  <div class="divider">&nbsp;</div>

  <div class="grid">
    <p class="bold">Performance</p>
    <p>
      We use third-party tools to monitor production errors and improve the user
      experience.
    </p>
    <div>
      <mat-slide-toggle
        formControlName="performanceCookies"
        [checked]="true"
      ></mat-slide-toggle>
    </div>
  </div>

  <div class="divider">&nbsp;</div>

  <div class="buttons">
    <button
      class="autofocus"
      mat-raised-button
      mat-dialog-close
      color="primary"
      (click)="reject()"
    >
      Reject
    </button>
    <button
      class="autofocus"
      mat-raised-button
      mat-dialog-close
      color="primary"
      (click)="accept()"
    >
      Accept
    </button>
  </div>
</form>
