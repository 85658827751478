export interface RegionData {
  name: string;
  email: string;
}

export interface IpServiceResponse {
  city: string;
  country: string;
  countryCode: string;
}

export interface RegionResponse {
  isReserved: boolean;
  regionOwner: Organization;
  region: Region;
}

export interface Region {
  email: string;
  name: string;
}

// Simplified org interface that we receive from our API

export interface Organization {
  email: string;
  b2cTokenPrice: number;
  name: string;
  b2cPaymentType: PaymentTypes;
  b2cCurrency: string;
}

export interface OrgResponse {
  organization: Organization;
}

export enum PaymentTypes {
  SINGLE_PAYMENT = 'Single Payment',
  MONTHLY_INSTALLMENT_PAYMENT = 'Monthly installment payment',
  SPLIT_PAYMENTS = 'Split Payments',
}
